import { useContext, useEffect, useState } from "react";
import {
  CheckoutContextType,
  CheckoutOfferContextType,
} from "../../../utils/Interfaces/Payment";
import {
  CheckoutContext,
  CheckoutOfferContext,
} from "../../../utils/GeneralContext";
import {
  BoldMediumText,
  DividerSection,
  MainButton,
  SmallText,
  TitleMediumText,
} from "../../../utils/GlobalStyles";
import { CardWrapper, CheckoutStepsWrapper } from "../styles";
import {
  CheckoutBoxLowerSection,
  CheckoutBoxUpperSection,
  CheckoutDetailsBox,
  SubDividerSection,
} from "../BillingInformation/styles";
import { Box, Link, TextField } from "@mui/material";
import { COLORS } from "../../../assets/theme";
import validator from "validator";

const OfferCaseForm = () => {
  const { setStep } = useContext(CheckoutContext) as CheckoutContextType;
  const {
    senderEmail,
    senderName,
    receiverEmail,
    receiverName,
    message,
    setMessage,
    setReceiverEmail,
    setReceiverName,
    setSenderEmail,
    setSenderName,
  } = useContext(CheckoutOfferContext) as CheckoutOfferContextType;

  const [formReady, setFormReady] = useState<boolean>(false);
  const [tempSenderName, setTempSenderName] = useState<string>(senderName);
  const [tempSenderEmail, setTempSenderEmail] = useState<string>(senderEmail);
  const [tempReceiverName, setTempReceiverName] =
    useState<string>(receiverName);
  const [tempReceiverEmail, setTempReceiverEmail] =
    useState<string>(receiverEmail);
  const [tempMessage, setTempMessage] = useState<string>(message);

  useEffect(() => {
    checkForm();
  }, [
    tempSenderName,
    tempSenderEmail,
    tempReceiverName,
    tempReceiverEmail,
    tempMessage,
  ]);

  async function checkForm() {
    if (
      validator.isEmail(tempSenderEmail) &&
      tempSenderName !== "" &&
      validator.isEmail(tempReceiverEmail) &&
      tempReceiverName !== "" &&
      tempMessage !== ""
    ) {
      setFormReady(true);
    } else {
      setFormReady(false);
    }
  }

  const handleGoToResume = () => {
    if (formReady) {
      setSenderName(tempSenderName);
      setSenderEmail(tempSenderEmail);
      setReceiverName(tempReceiverName);
      setReceiverEmail(tempReceiverEmail);
      setMessage(tempMessage);
      setStep("RESUME");
    }
  };

  return (
    <CheckoutStepsWrapper>
      <TitleMediumText>Checkout para oferta</TitleMediumText>
      <CheckoutDetailsBox>
        <CardWrapper>
          <CheckoutBoxUpperSection>
            <BoldMediumText sx={{ whiteSpace: "wrap" }}>
              Dados para enviar a prenda
            </BoldMediumText>
          </CheckoutBoxUpperSection>
          <DividerSection sx={{ margin: "0" }} />
          <Box
            display="flex"
            gap={1}
            flexWrap={"wrap"}
            justifyContent="space-between"
          >
            <TextField
              sx={{
                flex: "1 1 calc(50% - 8px)",
                minWidth: "200px",
                backgroundColor: "white",
              }}
              size="small"
              autoComplete="name"
              label="O teu nome"
              variant="outlined"
              value={tempSenderName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTempSenderName(e?.target?.value)
              }
            />
            <TextField
              sx={{
                flex: "1 1 calc(50% - 8px)",
                minWidth: "200px",
                backgroundColor: "white",
              }}
              size="small"
              autoComplete="email"
              label="O teu email"
              variant="outlined"
              placeholder="name@mail.com"
              value={tempSenderEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTempSenderEmail(e?.target?.value)
              }
            />
            <TextField
              sx={{
                flex: "1 1 calc(50% - 8px)",
                minWidth: "200px",
                backgroundColor: "white",
              }}
              size="small"
              label="O nome do destinatário"
              variant="outlined"
              value={tempReceiverName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTempReceiverName(e?.target?.value)
              }
            />
            <TextField
              sx={{
                flex: "1 1 calc(50% - 8px)",
                minWidth: "200px",
                backgroundColor: "white",
              }}
              size="small"
              label="O email do destinatário"
              variant="outlined"
              placeholder="name@mail.com"
              value={tempReceiverEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTempReceiverEmail(e?.target?.value)
              }
            />
          </Box>
          <TextField
            label="Mensagem a enviar"
            name="message"
            sx={{ backgroundColor: COLORS.white }}
            variant="outlined"
            multiline
            rows={5}
            fullWidth
            placeholder="Escreve a tua mensagem para o destinatário..."
            value={tempMessage}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTempMessage(e?.target?.value)
            }
          />
          <SmallText>
            O destinatário vai receber um email com o teu nome, e a tua
            mensagem, bem como um voucher com todos os passos necessários para
            poder jogar este caso.
          </SmallText>
          <SubDividerSection sx={{ margin: "0" }} />
          <CheckoutBoxLowerSection>
            <MainButton
              sx={{ opacity: formReady ? "1" : "0.5" }}
              disabled={!formReady}
              size="medium"
              fullWidth
              onClick={handleGoToResume}
            >
              <BoldMediumText>Ver resumo</BoldMediumText>
            </MainButton>
            {/* <SmallText>
              Os códigos promocionais serão aplicados no próximo passo
            </SmallText> */}
          </CheckoutBoxLowerSection>
        </CardWrapper>
      </CheckoutDetailsBox>
    </CheckoutStepsWrapper>
  );
};

export default OfferCaseForm;
