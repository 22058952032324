import { useContext, useEffect } from "react";
import { CheckoutContextType } from "../../../utils/Interfaces/Payment";
import { CheckoutContext } from "../../../utils/GeneralContext";
import Payment from "../../../components/CheckoutSteps/Payment";
import OfferCaseForm from "../../../components/CheckoutSteps/OfferCaseForm";
import OfferCaseResume from "../../../components/CheckoutSteps/OfferCaseResume";

const SelectCheckoutOfferPage = () => {
  const { step, setStep, clientSecret } = useContext(
    CheckoutContext
  ) as CheckoutContextType;

  useEffect(() => {
    if (clientSecret) {
      setStep("PAYMENT");
    }
  }, [clientSecret]);

  const CheckoutOfferPage = () => {
    switch (step) {
      case "VOUCHER_FORM":
        return <OfferCaseForm />;
      case "RESUME":
        return <OfferCaseResume />;
      case "PAYMENT":
        return <Payment />;
      default:
        return <OfferCaseForm />;
    }
  };

  return <CheckoutOfferPage />;
};

export default SelectCheckoutOfferPage;
