import { CartItemType, CartType, GroupedCartItemType } from "./Interfaces/Cart";
import { FileData } from "./Interfaces/Game";
import { CheckoutCreateSessionType } from "./Interfaces/Payment";

export function handleEnterKeyPressAction(action: any, event: any) {
  // ON ENTER KEY PRESS
  if (event.keyCode === 13) {
    action(event);
  }
}

export function dictToURL(dict: { [key: string]: any }) {
  var str = [];
  for (var p in dict) {
    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]));
  }
  return str.join("&");
}

export function calculate_cart_total_amount(updatedCart: CartItemType[]) {
  return updatedCart?.reduce(
    (total: number, item: CartItemType) => total + Number(item.price),
    0
  );
}

export function structureFilesIntoFolders(objects: any) {
  const organizedData: { [key: string]: FileData[] } = {};

  objects.forEach((object: any) => {
    const keyParts = object.Key.replace("game/", "").split("/");
    const mainFolder = keyParts[0];

    if (keyParts.length > 1 && keyParts[1] !== "") {
      if (!organizedData[mainFolder]) {
        organizedData[mainFolder] = [];
      }
      if (keyParts.length > 1) {
        const content = keyParts.slice(1).join("/");
        organizedData[mainFolder].push({
          name: keyParts.slice(1).join("/").split(".")[0],
          url: object.signedUrl,
          type: object.Key.split(".").pop(),
        });
      }
    }
  });

  return organizedData;
}

export function generateCheckoutSessionItems(
  returnUrl: string,
  userId: string,
  userEmail: string,
  groupedCartItems: GroupedCartItemType[],
  cart: CartType
) {
  const result: CheckoutCreateSessionType = {
    returnUrl: returnUrl,
    userId: userId,
    userEmail: userEmail,
    totalAmount: cart.totalAmount,
    orderItems: cart.items.map((item) => ({
      caseId: item.caseId,
      amount: item.price,
    })),
    items: groupedCartItems.map((item) => ({
      price: item.priceId,
      quantity: item.quantity,
    })),
  };
  return result;
}
