import { useContext, useEffect, useRef, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { BoldSmallText, MainButton } from "../../utils/GlobalStyles";
import { ContactUsWrapper, FormBox } from "./styles";
import { TextField } from "@mui/material";
import validator from "validator";
import ActionInformation from "../../components/ActionInformation";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { COLORS } from "../../assets/theme";
import { useMutation } from "@tanstack/react-query";
import { postData } from "../../utils/endpoints/api";
import SmallLoader from "../../components/loaders/SmallLoader";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";

function ContactUs() {
  const { setWarningMessage } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const form = useRef<HTMLFormElement>(null);
  const [formSuccess, setFormSuccess] = useState(0);
  const [formReady, setFormReady] = useState(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [topic, setTopic] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const sendContactFormMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("mail/send-contact-form", params, body, false, true),
    onSuccess: () => {
      setFormSuccess(1);
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao submeter a mensagem de contacto. Tenta mais tarde!",
      ]);
    },
  });

  useEffect(() => {
    function checkFields() {
      if (
        name !== "" &&
        validator.isEmail(email) &&
        topic !== "" &&
        message !== ""
      ) {
        setFormReady(true);
      } else {
        setFormReady(false);
      }
    }
    checkFields();
  }, [name, validator, topic, message]);

  const handleSendEmail = async () => {
    if (formReady && form.current) {
      try {
        const contactFormJson = {
          formName: name,
          formEmail: email,
          formTopic: topic,
          formMessage: message,
        };

        await sendContactFormMutation.mutateAsync({
          params: {},
          body: contactFormJson,
        });
      } catch (error) {
        console.error("Error sending form contact email:", error);
      }
    }
  };

  const isLoading = sendContactFormMutation.isPending;

  return (
    <ContactUsWrapper>
      <PageHeader
        heading="Contacta-nos"
        section={""}
        content=" Com sugestões de melhorias, problemas técnicos, ou só para dar o teu feedback!"
      />
      {isLoading ? (
        <SmallLoader />
      ) : formSuccess === 0 ? (
        <FormBox ref={form}>
          <TextField
            label="Nome"
            autoComplete="name"
            sx={{ backgroundColor: COLORS.white }}
            name="name"
            variant="outlined"
            fullWidth
            placeholder="Detetive Paulo"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e?.target?.value)
            }
          />
          <TextField
            label="Email"
            sx={{ backgroundColor: COLORS.white }}
            autoComplete="email"
            name="email"
            variant="outlined"
            fullWidth
            placeholder="name@email.com"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e?.target?.value)
            }
          />
          <TextField
            label="Assunto"
            name="topic"
            variant="outlined"
            sx={{ backgroundColor: COLORS.white }}
            fullWidth
            placeholder="Diz-nos em que te podemos ajudar"
            value={topic}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTopic(e?.target?.value)
            }
          />
          <TextField
            label="Mensagem"
            name="message"
            sx={{ backgroundColor: COLORS.white }}
            variant="outlined"
            multiline
            rows={6}
            fullWidth
            placeholder="Escreve a tua mensagem..."
            value={message}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMessage(e?.target?.value)
            }
          />
          <MainButton disabled={!formReady} onClick={handleSendEmail}>
            <BoldSmallText>Enviar</BoldSmallText>
          </MainButton>
        </FormBox>
      ) : formSuccess === 1 ? (
        <ActionInformation
          message="Mensagem enviada com sucesso. Serás contactado em breve, detetive! <br/> Verifica a pasta de spam, caso não recebas nada."
          icon={VerifiedRoundedIcon}
          iconColor={COLORS.greenOne}
          destinationText="a página principal"
          destinationUrl="/"
        />
      ) : (
        formSuccess === -1 && (
          <ActionInformation
            message="Ocorreu um erro ao enviar a mensagem. Tenta novamente mais tarde..."
            icon={NewReleasesIcon}
            iconColor={COLORS.redTwo}
            destinationText="a página principal"
            destinationUrl="/"
          />
        )
      )}
    </ContactUsWrapper>
  );
}

export default ContactUs;
