import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { signUp } from "aws-amplify/auth";
import validator from "validator";
import {
  AuthenticationContext,
  GeneralAppDataContext,
} from "../../../../utils/GeneralContext";
import PasswordChecker from "../../../../components/PasswordChecker";
import { handleEnterKeyPressAction } from "../../../../utils/HelpFunctions";
import {
  AuthMainContent,
  FormBox,
  InputBox,
  PasswordCheckerBox,
  TermsAndConditionsBox,
  TermsAndConditionsLink,
} from "../styles";
import {
  BoldSmallText,
  MainButton,
  MediumText,
  TitleMediumText,
  SmallText,
  UnderlinedButton,
} from "../../../../utils/GlobalStyles";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
} from "@mui/material";
import {
  AuthenticationContextType,
  GeneralAppDataContextType,
} from "../../../../utils/Interfaces/Global";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { COLORS } from "../../../../assets/theme";
import { generatePortugueseError } from "../../../../utils/Functions/CognitoErrorTranslator";

function SignUp() {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { setIsLoading } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const {
    setStep,
    setExtraStepData,
    setUsername,
    cognitoMessage,
    setCognitoMessage,
  } = useContext(AuthenticationContext) as AuthenticationContextType;
  const [formReady, setFormReady] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const [birthdate, setBirthdate] = useState("1990-01-01");
  const [passswordIsValid, setPasswordIsValid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [noNewsletter, setNoNewsletter] = useState(false);

  useEffect(() => {
    function checkFields() {
      if (
        firstName !== "" &&
        lastName !== "" &&
        birthdate !== "" &&
        validator.isEmail(email) &&
        passswordIsValid &&
        termsAndConditions
      ) {
        setFormReady(true);
      } else {
        setFormReady(false);
      }
    }
    checkFields();
  }, [
    firstName,
    lastName,
    birthdate,
    email,
    passswordIsValid,
    termsAndConditions,
  ]);

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const minDate = "1900-01-01";
    const maxDate = "2024-01-01";
    const inputDate = event.target.value;
    if (inputDate > maxDate) {
      setBirthdate(maxDate);
    } else {
      setBirthdate(inputDate);
    }
  };

  async function handleSignup(
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    if (formReady) {
      setIsLoading(true);
      await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            given_name: firstName,
            family_name: lastName,
            birthdate: birthdate,
          },
          autoSignIn: true,
        },
      })
        .then(async ({ isSignUpComplete, userId, nextStep }) => {
          setIsLoading(false);
          if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
            if (!noNewsletter) {
              setExtraStepData("RegisterNewsletter");
            }
            setUsername(email);
            setStep(nextStep.signUpStep);
          }
        })
        .catch((error: Error) => {
          setCognitoMessage(generatePortugueseError(error));
          setIsLoading(false);
        });
    }
  }

  const handleTogglePassword = (
    setter: Dispatch<SetStateAction<boolean>>,
    value: boolean
  ) => setter(!value);

  return (
    <AuthMainContent>
      <TitleMediumText>Sê um detetive!</TitleMediumText>
      <MediumText mt={1} mb={3}>
        Insere os teus dados para te registares.
      </MediumText>
      <FormBox>
        <InputBox>
          <TextField
            label="Primeiro nome"
            autoComplete="given-name"
            sx={{ backgroundColor: "white" }}
            variant="outlined"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFirstName(e?.target?.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleSignup, e);
            }}
          />
          <TextField
            label="Último nome"
            autoComplete="family-name"
            sx={{ backgroundColor: "white" }}
            variant="outlined"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setLastName(e?.target?.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleSignup, e);
            }}
          />
          <TextField
            label="Email"
            sx={{ backgroundColor: "white" }}
            variant="outlined"
            autoComplete="email"
            placeholder="name@mail.com"
            type="email"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e?.target?.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleSignup, e);
            }}
          />
          <TextField
            label="Data de nascimento"
            value={birthdate}
            variant="outlined"
            autoComplete="bday"
            sx={{
              backgroundColor: "white",
            }}
            inputProps={{
              style: {
                display: (isSafari && isMobile) ? "flex": "block",
                height: (isSafari && isMobile) ? "55px": "25px",
                backgroundColor: "white",
              },
            }}
            InputProps={{
              style: {
                padding: (isSafari && isMobile) ? "0 7px": "unset",
              },
            }}
            type="date"
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleSignup, e);
            }}
            onChange={handleDateChange}
          />
          <TextField
            label="Password"
            sx={{ backgroundColor: "white" }}
            variant="outlined"
            placeholder="***********"
            autoComplete="new-password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ color: COLORS.black, marginRight: "-5px" }}
                  onClick={() =>
                    handleTogglePassword(setShowPassword, showPassword)
                  }
                >
                  {showPassword ? (
                    <Visibility sx={{ fontSize: 13 }} />
                  ) : (
                    <VisibilityOff sx={{ fontSize: 13 }} />
                  )}
                </IconButton>
              ),
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e?.target?.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleSignup, e);
            }}
          />
          <TextField
            label="Confirmar password"
            sx={{ backgroundColor: "white" }}
            variant="outlined"
            placeholder="***********"
            type={showConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ color: COLORS.black, marginRight: "-5px" }}
                  onClick={() =>
                    handleTogglePassword(
                      setShowConfirmPassword,
                      showConfirmPassword
                    )
                  }
                >
                  {showConfirmPassword ? (
                    <Visibility sx={{ fontSize: 13 }} />
                  ) : (
                    <VisibilityOff sx={{ fontSize: 13 }} />
                  )}
                </IconButton>
              ),
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setConfirmPassword(e?.target?.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleSignup, e);
            }}
          />
          <PasswordCheckerBox>
            <PasswordChecker
              password={password}
              confirmPassword={confirmPassword}
              setPasswordIsValid={setPasswordIsValid}
            />
          </PasswordCheckerBox>
        </InputBox>
      </FormBox>
      <FormBox>
        <TermsAndConditionsBox>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAndConditions}
                  onChange={(event) =>
                    setTermsAndConditions(event.target.checked)
                  }
                />
              }
              label={
                <SmallText color="gray !important">
                  Concordo com os&nbsp;
                  <TermsAndConditionsLink
                    href="/terms-and-conditions"
                    target="_blank"
                  >
                    Termos & Condições*
                  </TermsAndConditionsLink>
                </SmallText>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={noNewsletter}
                  onChange={(event) => setNoNewsletter(event.target.checked)}
                />
              }
              label={
                <SmallText color="gray !important">
                  Não pretendo receber comunicações de marketing
                </SmallText>
              }
            />
          </FormGroup>
        </TermsAndConditionsBox>
        <MainButton
          sx={{ margin: "20px 0" }}
          fullWidth
          disabled={!formReady}
          onClick={handleSignup}
        >
          <BoldSmallText>Registar</BoldSmallText>
        </MainButton>
      </FormBox>
      {cognitoMessage && (
        <BoldSmallText color={`${COLORS.redTwo} !important`}>
          {cognitoMessage}
        </BoldSmallText>
      )}
      <MediumText
        color={`${COLORS.grayFour} !important`}
        mt={1}
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        Já tens conta?{" "}
        <UnderlinedButton onClick={() => setStep("LOG_IN")}>
          Iniciar sessão
        </UnderlinedButton>
      </MediumText>
    </AuthMainContent>
  );
}

export default SignUp;
