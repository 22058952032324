import { Route, Routes } from "react-router-dom";
import CaseGamePage from "../../components/CaseGame/CaseGamePage";
import CantAccess from "../../components/CaseGame/CantAccess";
import PrivateGame from "../PrivateGame";
import { MyCaseAccessType } from "../../utils/Interfaces/Game";

const gameRoutes = [
  { path: "/artist-death/:orderItemId" },
  { path: "/last-harvest/:orderItemId" },
];

export default function CaseGame() {
  return (
    <Routes>
      <Route path="/*" element={<CantAccess reason="Caso não encontrado." />} />
      {gameRoutes.map(({ path }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateGame>
              <CaseGamePage caseData={{} as MyCaseAccessType} />
            </PrivateGame>
          }
        />
      ))}
    </Routes>
  );
}
