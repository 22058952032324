import {
  BoldMediumText,
  BoldSmallText,
  MediumText,
  SmallText,
} from "../../../../utils/GlobalStyles";
import { GroupedCartItemType } from "../../../../utils/Interfaces/Cart";
import {
  CheckoutCartItemBox,
  CheckoutCartItemImage,
  LeftColumnBox,
  SubCostBox,
  TextInfoBox,
} from "./styles";
import { COLORS } from "../../../../assets/theme";

export default function CheckoutCartItem({
  imageUrl,
  name,
  amount,
  unitPrice,
  quantity,
  path,
}: GroupedCartItemType) {
  return (
    <CheckoutCartItemBox>
      <CheckoutCartItemImage
        src={`images/cases/${path}/main_horizontal.png`}
        alt="cart-item"
      />
      <TextInfoBox>
        <LeftColumnBox>
          <MediumText>{name}</MediumText>
          <SubCostBox>
            <BoldSmallText>{quantity}</BoldSmallText>
            <BoldSmallText>x</BoldSmallText>
            <BoldSmallText>€{unitPrice}</BoldSmallText>
          </SubCostBox>
        </LeftColumnBox>
        <MediumText color={`${COLORS.black} !important`}>€{amount}</MediumText>
      </TextInfoBox>
    </CheckoutCartItemBox>
  );
}
