import { useState } from "react";
import {
  AspectRatioIconWrapper,
  AudioPlayerBox,
  FileBox,
  FileIconBox,
  FileNameBox,
  FileWrapper,
} from "./styles";
import { SmallText } from "../../../../../utils/GlobalStyles";
import { generateFileTypeIcon } from "../../../../../utils/Functions/CaseFilesHelpers";
import { FileItemProps } from "../../../../../utils/Interfaces/Game";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ModalFileItem from "./ModalFileItem";
import { COLORS } from "../../../../../assets/theme";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function FileItem({ file }: FileItemProps) {
  const [fileOpen, setFileOpen] = useState(false);

  const handleOpenFile = () => {
    if (file.type === "pdf" || file.type === "jpg") {
      setFileOpen(!fileOpen);
    }
  };

  return (
    <>
      <FileWrapper
        key={file.name}
        onClick={handleOpenFile}
        isPdforImage={file.type === "pdf" || file.type === "jpg"}
      >
        <FileIconBox>{generateFileTypeIcon(file.type || "")}</FileIconBox>
        {file.type === "mp3" ? (
          <AudioPlayerBox>
            <AudioPlayer
              style={{ backgroundColor: COLORS.grayTwo }}
              src={`${file.url}`}
              showSkipControls={false}
              showJumpControls={false}
              loop={false}
              customAdditionalControls={[<></>]}
            />
          </AudioPlayerBox>
        ) : (
          <div style={{ position: "relative" }}>
            {(file.type === "pdf" || file.type === "jpg") && (
              <FileBox className="child-box" onClick={handleOpenFile}>
                {(file.type === "pdf" || file.type === "jpg") && (
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                    }}
                    onClick={handleOpenFile}
                  >
                    <AspectRatioIconWrapper />
                  </button>
                )}
              </FileBox>
            )}
            {file.type === "jpg" ? (
              <img
                style={{
                  height: "295px",
                  width: "275px",
                  objectFit: "cover",
                }}
                src={file.url}
                alt={file.name}
              />
            ) : file.type === "pdf" ? (
              <Document file={file.url}>
                <Page pageNumber={1} width={275} height={200} />
              </Document>
            ) : (
              <iframe
                src={`${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
                height="295px"
                width="275px"
                allowFullScreen
              />
            )}
          </div>
        )}
        <FileNameBox>
          <SmallText>{file.name}</SmallText>
        </FileNameBox>
      </FileWrapper>
      <ModalFileItem
        file={file}
        fileOpen={fileOpen}
        handleOpenFile={handleOpenFile}
      />
    </>
  );
}

export default FileItem;
