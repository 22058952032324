import { Box, styled } from "@mui/material";

export const CheckoutOfferCartItemBox = styled(Box)`
  display: flex;
  gap: 15px;
  height: fit-content;
`;

export const CheckoutOfferCartItemImage = styled("img")`
  width: 90px;
  height: 100%;
  border-radius: 6px;
  object-position: top;
  object-fit: cover;
`;

export const TextInfoBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

export const LeftColumnBox = styled(Box)`
  display: flex;
  flex-flow: column;
`;

export const SubCostBox = styled(Box)`
  display: flex;
  gap: 4px;
`;
