import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { CheckoutSessionStatusType } from "../../../utils/Interfaces/Payment";
import ActionInformation from "../../ActionInformation";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import { COLORS } from "../../../assets/theme";
import { getData } from "../../../utils/endpoints/api";
import BigLoader from "../../loaders/BigLoader";
import { useContext, useEffect } from "react";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";

function ResultOffer() {
  const { setWarningMessage } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const navigate = useNavigate();
  const { sessionId } = useParams();

  const {
    data: sessionData,
    error: checkoutSessionStatusError,
    isLoading,
  } = useQuery<CheckoutSessionStatusType, Error>({
    queryKey: ["checkoutSessionStatus"],
    queryFn: () => getData("checkout/session", { sessionId: sessionId! }, true),
    enabled: !!sessionId,
  });

  useEffect(() => {
    if (checkoutSessionStatusError) {
      setWarningMessage([
        "error",
        "Houve um erro com o checkout. Tenta mais tarde!",
      ]);
    }
  }, [checkoutSessionStatusError]);

  const CheckoutOfferResultPage = () => {
    switch (sessionData?.status) {
      case "complete":
        return (
          <ActionInformation
            message="Pagamento efetuado com successo, detetive!"
            icon={VerifiedRoundedIcon}
            iconColor={COLORS.greenOne}
            destinationText="os casos comprados"
            destinationUrl="/account"
          />
        );
      default:
        navigate("/shopping-cart");
        return null;
    }
  };

  return (
    <>
      {isLoading ? <BigLoader /> : sessionData && <CheckoutOfferResultPage />}
    </>
  );
}

export default ResultOffer;
