import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import ImportContactsRoundedIcon from "@mui/icons-material/ImportContactsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ShoppingCartIconNavbar from "../ShoppingCartIconNavbar";
import { NavLink, useNavigate } from "react-router-dom";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import {
  MobileMenuButton,
  MobileNavbarMenuOptionsBox,
  MobileWrapper,
  NavItemBox,
  NavbarMenuOptionsBox,
  NavbarRightSectionDesktop,
  NavbarBox,
  UserCircleIconComp,
  NavbarWrapperDesktop,
  LoggedUserInitialsButton,
  MobileRightBox,
  NavbarWrapperMobile,
  MobileNavbarProfileButton,
} from "./styles";
import {
  BoldSmallText,
  MainButton,
  MediumText,
} from "../../utils/GlobalStyles";
import { Drawer } from "@mui/material";
import {
  GeneralAppDataContextType,
  NavItemProps,
} from "../../utils/Interfaces/Global";
import UpperBanner from "./UpperBanner";
import React from "react";

const NAV_MENU = [
  {
    name: "Início",
    icon: HomeRoundedIcon,
    href: "/",
  },
  {
    name: "Casos",
    icon: Inventory2RoundedIcon,
    href: "/cases",
  },
  {
    name: "Como começar",
    icon: ImportContactsRoundedIcon,
    href: "/how-to-start",
  },
  {
    name: "Sobre nós",
    icon: InfoRoundedIcon,
    href: "/about-us",
  },
];

function NavItem({ children, href }: NavItemProps) {
  return (
    <NavLink to={href}>
      <NavItemBox>{children}</NavItemBox>
    </NavLink>
  );
}

function Navbar() {
  const { user, isAuthenticated } = React.useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  function handleOpen() {
    setOpen((cur) => !cur);
  }

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpen(false)
    );
  }, []);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleOpenMyCases = () => {
    navigate("/account", { state: { tab: 0 } });
  };

  const handleNavigateToAccount = () => {
    navigate("/account", { state: { tab: 1 } });
  };

  return (
    <>
      <NavbarWrapperDesktop>
        <UpperBanner />
        <NavbarBox>
          <NavLink to="/">
            <img
              width={80}
              src={require("../../assets/images/desarquivados_main_logo.png")}
              alt="desarquivados logo"
            />
          </NavLink>
          <NavbarMenuOptionsBox>
            {NAV_MENU?.map(({ name, icon: Icon, href }) => (
              <NavItem key={name} href={href}>
                <Icon style={{ width: "20px", height: "20px" }} />
                <MediumText>{name}</MediumText>
              </NavItem>
            ))}
          </NavbarMenuOptionsBox>
          <NavbarRightSectionDesktop>
            {isAuthenticated ? (
              <MainButton
                onClick={handleOpenMyCases}
                sx={{ padding: "8px 20px" }}
                size="medium"
              >
                <BoldSmallText>Os meus casos</BoldSmallText>
              </MainButton>
            ) : (
              <NavLink to="/cases">
                <MainButton sx={{ padding: "8px 20px" }} size="medium">
                  <BoldSmallText>Resolver casos</BoldSmallText>
                </MainButton>
              </NavLink>
            )}
            {user ? (
              <LoggedUserInitialsButton onClick={handleNavigateToAccount}>
                {user?.firstName?.substring(0, 1).toUpperCase()}
                {user?.lastName?.substring(0, 1).toUpperCase()}
              </LoggedUserInitialsButton>
            ) : (
              <NavLink to="/account">
                <UserCircleIconComp />
              </NavLink>
            )}
            <ShoppingCartIconNavbar />
          </NavbarRightSectionDesktop>
        </NavbarBox>
      </NavbarWrapperDesktop>
      <NavbarWrapperMobile>
        <UpperBanner />
        <NavbarBox>
          <NavLink to="/">
            <img
              width={80}
              src={require("../../assets/images/desarquivados_main_logo.png")}
              alt="desarquivados logo"
            />
          </NavLink>
          <MobileRightBox>
            <MobileNavbarProfileButton onClick={toggleDrawer(false)}>
              <NavLink to="/account">
                {user ? (
                  <LoggedUserInitialsButton onClick={handleNavigateToAccount}>
                    {user?.firstName?.substring(0, 1).toUpperCase()}
                    {user?.lastName?.substring(0, 1).toUpperCase()}
                  </LoggedUserInitialsButton>
                ) : (
                  <NavLink to="/account">
                    <UserCircleIconComp />
                  </NavLink>
                )}
              </NavLink>
            </MobileNavbarProfileButton>
            <ShoppingCartIconNavbar />
            <MobileMenuButton variant="text" onClick={handleOpen}>
              {open ? (
                <CloseRoundedIcon
                  strokeWidth={2}
                  style={{ width: "24px", height: "24px" }}
                />
              ) : (
                <MenuRoundedIcon
                  strokeWidth={2}
                  style={{ width: "24px", height: "24px" }}
                />
              )}
            </MobileMenuButton>
          </MobileRightBox>
        </NavbarBox>
      </NavbarWrapperMobile>
      <Drawer
        sx={{ paddingTop: "50px", zIndex: 10 }}
        anchor={"top"}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <MobileWrapper>
          <MobileNavbarMenuOptionsBox onClick={toggleDrawer(false)}>
            {NAV_MENU?.map(({ name, icon: Icon, href }) => (
              <NavItem key={name} href={href}>
                <Icon style={{ width: "20px", height: "20px" }} />
                {name}
              </NavItem>
            ))}
          </MobileNavbarMenuOptionsBox>
        </MobileWrapper>
      </Drawer>
    </>
  );
}

export default Navbar;
