import { Box } from "@mui/material";
import {
  ActivateButton,
  BoughtCaseCardBox,
  ContentSection,
  Image,
  LowerButtonBox,
  UpperContentSection,
  WriteReviewButton,
} from "./styles";
import {
  BoldMediumText,
  BoldSmallText,
  MainButton,
  MediumText,
  SmallText,
} from "../../utils/GlobalStyles";
import { NavLink, useNavigate } from "react-router-dom";
import { MyCaseType } from "../../utils/Interfaces/Account";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import ActivateCaseModal from "./ActivateCaseModal";
import CaseReview from "../CaseReview";
import React from "react";
import { COLORS, PALETTE } from "../../assets/theme";
import { postData } from "../../utils/endpoints/api";
import LocalLoader from "../loaders/LocalLoader";

const status_translation: { [key: string]: string } = {
  COMPLETED: "CONCLUÍDO",
  NOT_ACTIVATED: "POR ATIVAR",
  ACTIVE: "ATIVO",
  EXPIRED: "EXPIRADO",
};

function BoughtCaseCard(props: MyCaseType) {
  const { user, setWarningMessage } = React.useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const [caseReviewModal, setCaseReviewModal] = React.useState(false);

  const [activateCaseModal, setActivateCaseModal] = React.useState(false);
  const [remainDays, setRemainDays] = React.useState(0);
  const [expiredDate, setExpiredDate] = React.useState<string>("");
  const queryClient = useQueryClient();
  const activateCaseMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("my-case/activate", params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["myCases"] });
      navigate(`/case-game/${props?.path}/${props?.orderItemId}`);
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao ativar o caso. Tenta mais tarde!",
      ]);
    },
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    if (props?.status === "EXPIRED") {
      var expirationDate = new Date(props?.activationDate);
      expirationDate.setDate(
        expirationDate.getDate() + props?.expirationLength
      );
      setExpiredDate(expirationDate.toISOString().substring(0, 10));
    } else if (props?.status === "ACTIVE") {
      const today = new Date();
      var expirationDate = new Date(props?.activationDate);
      expirationDate.setDate(
        expirationDate.getDate() + props?.expirationLength
      );
      const differenceDays = Math.floor(
        (expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
      );
      setRemainDays(differenceDays);
    }
  }, [props]);

  const handleActivateCase = async () => {
    try {
      await activateCaseMutation.mutateAsync({
        params: {
          orderItemId: props?.orderItemId!.toString(),
          userId: user?.id!,
        },
        body: {},
      });
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleCaseReviewModal = () => setCaseReviewModal(!caseReviewModal);

  const handleActivateCaseModal = () =>
    setActivateCaseModal(!activateCaseModal);

  const isLoading = activateCaseMutation.isPending;

  return (
    <>
      <BoughtCaseCardBox>
        <Box mb={2.5}>
          <Image
            width={200}
            height={200}
            src={`images/cases/${props.path}/main_vertical.png`}
            alt={props.name}
          />
        </Box>
        <ContentSection>
          <Box>
            <UpperContentSection>
              {props.difficulty && (
                <SmallText color={`${COLORS.redTwo} !important`}>
                  Dificuldade: {props.difficulty}/10
                </SmallText>
              )}
              <BoldSmallText>ID: {props.orderItemId}</BoldSmallText>
            </UpperContentSection>
            <NavLink to={`/case-game/${props?.path}/${props.orderItemId}`}>
              <BoldMediumText my={1}>
                {props.name}{" "}
                <span
                  style={{
                    color:
                      props?.status === "ACTIVE"
                        ? COLORS.orangeOne
                        : props?.status === "NOT_ACTIVATED"
                          ? COLORS.greenTwo
                          : props?.status === "COMPLETED"
                            ? PALETTE.secondaryFour
                            : COLORS.redTwo,
                    fontSize: 14,
                  }}
                >
                  {status_translation[props?.status] || props?.status}
                </span>
              </BoldMediumText>
            </NavLink>
            <MediumText color={`${COLORS.grayFour} !important`}>
              {props.teaserSummary}
            </MediumText>
            {props?.status === "EXPIRED" ? (
              <BoldSmallText color={`${COLORS.redTwo} !important`} my={1}>
                EXPIRADO A: {expiredDate}
              </BoldSmallText>
            ) : (
              props.activationDate && (
                <BoldSmallText
                  color={`${PALETTE.secondaryTwo} !important`}
                  my={1}
                >
                  DATA DE ATIVAÇÃO: {props.activationDate.split(" ")[0]}
                </BoldSmallText>
              )
            )}
            {props?.status === "ACTIVE" && (
              <SmallText color={`${COLORS.greenTwo} !important`} my={1}>
                Tempo restante: {remainDays} dias
              </SmallText>
            )}
            {props?.status === "NOT_ACTIVATED" && (
              <BoldSmallText
                color={`${PALETTE.secondaryTwo} !important`}
                my={1}
              >
                VALIDADE QUANDO ATIVO: {props.expirationLength} dias
              </BoldSmallText>
            )}
          </Box>
          <Box mt={1}>
            {props?.status !== "NOT_ACTIVATED" && (
              <WriteReviewButton onClick={() => setCaseReviewModal(true)}>
                <SmallText>Fazer review</SmallText>
              </WriteReviewButton>
            )}
            <LowerButtonBox mt={1}>
              {props?.status === "ACTIVE" ? (
                <NavLink to={`/case-game/${props?.path}/${props.orderItemId}`}>
                  <MainButton fullWidth>
                    <BoldSmallText>Resolver caso</BoldSmallText>
                  </MainButton>
                </NavLink>
              ) : props?.status === "NOT_ACTIVATED" ? (
                isLoading ? (
                  <LocalLoader height="40" />
                ) : (
                  <ActivateButton
                    fullWidth
                    onClick={() => setActivateCaseModal(true)}
                  >
                    <BoldSmallText color={`${COLORS.white} !important`}>
                      Ativar caso
                    </BoldSmallText>
                  </ActivateButton>
                )
              ) : props?.status === "COMPLETED" ? (
                <NavLink to={`/case-game/${props?.path}/${props.orderItemId}`}>
                  <MainButton
                    fullWidth
                    sx={{
                      backgroundColor: PALETTE.secondaryFour,
                    }}
                  >
                    <BoldSmallText color={`${COLORS.white} !important`}>
                      Resolvido
                    </BoldSmallText>
                  </MainButton>
                </NavLink>
              ) : (
                <MainButton
                  fullWidth
                  sx={{
                    backgroundColor: "red",
                    opacity: 0.5,
                    color: `${COLORS.white} !important`,
                  }}
                  disabled
                >
                  <BoldSmallText>Expirado</BoldSmallText>
                </MainButton>
              )}
            </LowerButtonBox>
          </Box>
        </ContentSection>
      </BoughtCaseCardBox>
      <CaseReview
        caseId={props?.caseId}
        caseReviewModal={caseReviewModal}
        setCaseReviewModal={setCaseReviewModal}
        handleModalClose={handleCaseReviewModal}
      />
      <ActivateCaseModal
        expirationLength={props?.expirationLength}
        activateCase={activateCaseModal}
        handleActivateCase={handleActivateCase}
        handleModalClose={handleActivateCaseModal}
      />
    </>
  );
}

export default BoughtCaseCard;
