import { createElement } from "react";
import { getFileFromBucket } from "../../../../utils/Functions/DownloadFileFromBucket";
import {
  BoldMediumText,
  BoldSmallText,
  MainButton,
  FullWidthFullHeightButton,
  SmallText,
} from "../../../../utils/GlobalStyles";
import {
  BottomSectionWrapper,
  ContentSectionWrapper,
  FolderButton,
  FoldersBox,
  GameLeftContentWrapper,
} from "./styles";
import { GameLeftContentProps } from "../../../../utils/Interfaces/Game";
import MoveToInboxRoundedIcon from "@mui/icons-material/MoveToInboxRounded";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import { COLORS } from "../../../../assets/theme";
import { useLocation } from "react-router-dom";

function GameLeftContent({
  files,
  setIsLoading,
  caseData,
  handleModalOpen,
  activeFolder,
  setActiveFolder,
  foldersStructure,
}: GameLeftContentProps) {
  const location = useLocation();
  const isSharedCaseGame = location.pathname.startsWith("/shared-case");

  const handleDownloadAllFiles = async () => {
    setIsLoading(true);
    const zipFile = caseData?.path + ".zip";
    const bucketName = caseData?.path;
    await getFileFromBucket(bucketName, zipFile, "octet/stream")
      .then(() => {
        setIsLoading(false);
      })
      .catch((error: { message: string }) => {
        setIsLoading(false);
        console.log(error.message);
      });
  };

  return (
    <GameLeftContentWrapper>
      <ContentSectionWrapper>
        <BoldMediumText px={2} textAlign="left" mb={1}>
          Pastas
        </BoldMediumText>
        <FoldersBox>
          {Object.keys(files)?.map((folder, index) => (
            <FolderButton
              active={folder === activeFolder}
              key={index}
              onClick={() => setActiveFolder(folder)}
            >
              {createElement(foldersStructure[folder].icon, {
                style: {
                  width: "20px",
                  height: "20px",
                  color: COLORS.orangeOne,
                },
              })}
              <BoldSmallText color={`${COLORS.black} !important`}>
                {foldersStructure[folder].text}
              </BoldSmallText>
            </FolderButton>
          ))}
        </FoldersBox>
        <MainButton sx={{ width: "100%" }} onClick={handleDownloadAllFiles}>
          <CloudDownloadRoundedIcon style={{ width: "20px", height: "20px" }} />
          <BoldSmallText>Descarregar ficheiros*</BoldSmallText>
        </MainButton>
        <SmallText mt={1}>
          *Os ficheiros em formato áudio e vídeo não são descarregáveis
        </SmallText>
      </ContentSectionWrapper>
      {!isSharedCaseGame && (
        <BottomSectionWrapper>
          <FullWidthFullHeightButton
            bgColor={COLORS.blueFour}
            textColor={COLORS.white}
            onClick={handleModalOpen}
          >
            <MoveToInboxRoundedIcon style={{ width: "20px", height: "20px" }} />
            Submeter relatório
          </FullWidthFullHeightButton>
        </BottomSectionWrapper>
      )}
    </GameLeftContentWrapper>
  );
}

export default GameLeftContent;
