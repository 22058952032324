import { NavLink } from "react-router-dom";
import {
  BoldLargeText,
  BoldMediumText,
  MediumText,
  SecondaryButton,
  TitleMediumText,
} from "../../utils/GlobalStyles";
import { BoardImage, ContentBox, PromotedCaseWrapper } from "./styles";
import { COLORS } from "../../assets/theme";

function PromotedCase() {
  return (
    <PromotedCaseWrapper>
      <ContentBox>
        <BoldLargeText color={`${COLORS.redTwo} !important`}>
          NOVO
        </BoldLargeText>
        <TitleMediumText>A Última Colheita</TitleMediumText>
        <MediumText
          mt={2}
          mb={4}
          color={`${COLORS.grayFour} !important`}
          textAlign="center"
        >
          Constança Marques Oliveira, CEO e herdeira de uma das maiores empresas
          vinicolas do país (Herdade Marquês de Oliveira) foi encontrada morta
          no seu iate no 70º aniversário do seu pai e fundador da empresa. As
          motivos para a sua morte nunca foram bem explicados e vários detalhes
          escaparam à Policia de Portugal, que acabou por arquivar este caso. O
          que terá acontecido a Constança? Terá sido inveja do seu sucesso ou um
          crime de paixão?
        </MediumText>
        <NavLink to="/cases/last-harvest">
          <SecondaryButton size="large">
            <BoldMediumText color={`${COLORS.white} !important`}>
              Analisar caso
            </BoldMediumText>
          </SecondaryButton>
        </NavLink>
      </ContentBox>
      <NavLink to="/cases/last-harvest">
        <BoardImage
          src={require("../../assets/cases-content/suspects_board_last_harvest.png")}
          alt="agency logo"
        />
      </NavLink>
    </PromotedCaseWrapper>
  );
}

export default PromotedCase;
