import { useState } from "react";
import {
  CheckoutContext,
  CheckoutOfferContext,
} from "../../utils/GeneralContext";
import { CheckoutSessionType } from "../../utils/Interfaces/Payment";
import { Navigate, Route, Routes } from "react-router-dom";
import SelectCheckoutOfferPage from "./SelectCheckoutOfferPage";
import ReactGA from "react-ga4";
import ResultOffer from "../../components/CheckoutSteps/ResultOffer";

function CheckoutOffer() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  if (isProduction) {
    ReactGA.send({
      hitType: "pageview",
      page: "/checkout-offer",
      title: "CheckoutOffer",
    });
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "CheckoutOffer");
    }
  }

  const [step, setStep] = useState<string>("VOUCHER_FORM");
  const [clientSecret, setClientSecret] = useState<CheckoutSessionType | null>(
    null
  );
  const [senderEmail, setSenderEmail] = useState<string>("");
  const [senderName, setSenderName] = useState<string>("");
  const [receiverEmail, setReceiverEmail] = useState<string>("");
  const [receiverName, setReceiverName] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  return (
    <CheckoutContext.Provider
      value={{
        step,
        setStep,
        clientSecret,
        setClientSecret,
      }}
    >
      <CheckoutOfferContext.Provider
        value={{
          senderName,
          setSenderName,
          senderEmail,
          setSenderEmail,
          receiverName,
          setReceiverName,
          receiverEmail,
          setReceiverEmail,
          message,
          setMessage,
        }}
      >
        <Routes>
          <Route path="/*" element={<Navigate replace to="/" />} />
          <Route path="/result-offer/:sessionId" element={<ResultOffer />} />
          <Route path="/" element={<SelectCheckoutOfferPage />} />
        </Routes>
      </CheckoutOfferContext.Provider>
    </CheckoutContext.Provider>
  );
}

export default CheckoutOffer;
