import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { Box, Button, styled } from "@mui/material";
import { PALETTE, COLORS } from "../../assets/theme";

const NavbarWrapper = styled(Box)`
  border-bottom-width: 2px;
  border-color: ${PALETTE.secondaryFour};
  z-index: 11;
  background-color: ${COLORS.yellowOne};
`;

export const NavbarWrapperDesktop = styled(NavbarWrapper)`
  display: none;

  @media (min-width: 1024px) {
    display: inline-block;
  }
`;

export const NavbarWrapperMobile = styled(NavbarWrapper)`
  display: inline-block;
  position: sticky;
  top: 0;
  left: 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const NavbarBox = styled(Box)`
  position: relative;
  height: 80px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3px 20px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const NavbarRightSectionDesktop = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const NavItemBox = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    color: ${PALETTE.secondaryFour} !important;
    p {
      color: ${PALETTE.secondaryFour} !important;
    }
  }
`;

export const NavbarMenuOptionsBox = styled(Box)`
  display: flex;
  margin-left: 45px;
  gap: 30px;
  align-items: center;
`;

export const MobileWrapper = styled(Box)`
  padding: 20px;
  margin-top: 120px;
`;

export const MobileNavbarMenuOptionsBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 20px;
  margin: 5px 0;
`;

export const MobileNavbarProfileButton = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 5px;
`;

export const LoggedUserInitialsButton = styled("button")`
  display: inline-flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: ${COLORS.grayOne};
  background-color: ${PALETTE.secondaryTwo};

  &:hover {
    background-color: ${PALETTE.secondaryFour};
  }

  @media (min-width: 1024px) {
    margin-left: 8px;
  }
`;

export const MobileMenuButton = styled(Button)`
  display: inline-block;
  color: ${COLORS.black};
`;

export const UserCircleIconComp = styled(AccountCircleRoundedIcon)`
  width: 24px;
  height: 24px;
  color: ${PALETTE.secondaryTwo};

  &:hover {
    color: ${PALETTE.secondaryFour};
  }
  @media (min-width: 1024px) {
    margin-left: 8px;
  }
`;

export const MobileRightBox = styled(Box)`
  display: flex;
  align-items: center;
`;
