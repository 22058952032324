interface ErrorMessages {
  [key: string]: string;
}

const errorMessages: ErrorMessages = {
  UserNotFoundException: "Utilizador não encontrado",
  NotAuthorizedException: "Credenciais inválidas",
  UserNotConfirmedException: "Utilizador não confirmado",
  UsernameExistsException: "O email já existe",
  InvalidPasswordException: "Senha inválida",
  TooManyRequestsException: "Muitas tentativas. Tenta novamente mais tarde",
  PasswordResetRequiredException: "É necessário redefinir a senha",
  CodeMismatchException: "Código de verificação incorreto",
  ExpiredCodeException: "Código de verificação expirado",
  LimitExceededException: "Limite excedido. Tenta novamente mais tarde",
  MFAMethodNotFoundException: "Método MFA não encontrado",
  InternalErrorException: "Erro interno",
  InvalidLambdaResponseException: "Resposta inválida da função Lambda",
  AliasExistsException: "O alias já existe",
  AutoSignInException: "Por favor, tente fazer login novamente",
};

export function generatePortugueseError(error: any) {
  return errorMessages[error.name] || error.message;
}
