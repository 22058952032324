import "react-h5-audio-player/lib/styles.css";
import { Dialog, DialogContent } from "@mui/material";
import { Transition } from "../../../../../../utils/Transitions";
import { BoldMediumText } from "../../../../../../utils/GlobalStyles";
import { ModalFileItemProps } from "../../../../../../utils/Interfaces/Game";
import { CloseRoundedIconWrapper, DialogTitleWrapper } from "./styles";
import { COLORS } from "../../../../../../assets/theme";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useState } from "react";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function ModalFileItem({ file, handleOpenFile, fileOpen }: ModalFileItemProps) {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Dialog
      sx={{ position: "fixed", zIndex: 999 }}
      maxWidth={"md"}
      TransitionComponent={Transition}
      open={fileOpen}
      onClose={handleOpenFile}
      fullWidth
    >
      <DialogTitleWrapper mb={"10px"}>
        <BoldMediumText color={`${COLORS.white}`}>{file.name}</BoldMediumText>
        <button onClick={handleOpenFile}>
          <CloseRoundedIconWrapper />
        </button>
      </DialogTitleWrapper>
      <DialogContent sx={{ height: file.type === "pdf" ? "90vh" : "unset" }}>
        {file.type === "jpg" ? (
          <img
            style={{
              objectFit: "cover",
            }}
            src={file.url}
            alt={file.name}
          />
        ) : file.type === "pdf" ? (
          <Document file={file.url} onLoadSuccess={onDocumentLoadSuccess}>
            {numPages &&
              Array.from({ length: numPages }, (_, index) => (
                <Page
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={875}
                />
              ))}
          </Document>
        ) : (
          <iframe
            src={`${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
            style={{
              width: "100%",
              height: "100%",
            }}
            allowFullScreen
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ModalFileItem;
