import parse from "html-react-parser";
import { PALETTE, COLORS } from "../../../assets/theme";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ReviewsRoundedIcon from "@mui/icons-material/ReviewsRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import RedeemIcon from "@mui/icons-material/Redeem";
import Rating from "@mui/material/Rating";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import {
  BoldLargeText,
  BoldMediumText,
  BoldSmallText,
  DividerSection,
  MainButton,
  MediumText,
  NoBackgroundButton,
  SecondaryButton,
  SmallText,
} from "../../../utils/GlobalStyles";
import { Box } from "@mui/material";
import {
  CaseToBuyWrapper,
  ClassificationWrapper,
  CustomBgButton,
  DescriptionRules,
  DescriptionRulesItem,
  DiscountBox,
  PriceBox,
  ProductImage,
  ProductTitleBox,
  ShareButtonBox,
  TopRightContent,
  PriceButtonBox,
  ContentImage,
  ContentImageBox,
  ContentWrapper,
  ContentTextBox,
  CartBox,
} from "./styles";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import { GetCaseDetailsProps } from "../../../utils/Interfaces/Endpoints";
import { CaseType } from "../../../utils/Interfaces/Case";
import React, { useEffect, useState } from "react";
import {
  addItemToCart,
  removeItemFromCart,
} from "../../../components/CartItem/helpers";
import AddToCartMinusPlus from "../../../components/AddToCartMinusPlus";
import Reviews from "./Reviews";
import { deleteData, getData, postData } from "../../../utils/endpoints/api";
import BigLoader from "../../../components/loaders/BigLoader";
import LocalLoader from "../../../components/loaders/LocalLoader";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import TravelExploreRoundedIcon from "@mui/icons-material/TravelExploreRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function CaseToBuy({ caseId }: GetCaseDetailsProps) {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  const { user, groupedCart, setCart, setWarningMessage } = React.useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const [numberCartItems, setNumberCartItems] = useState<number>(0);

  const reviewsRef = React.useRef<HTMLInputElement | null>(null);
  const {
    data: caseDetails,
    error: caseDetailsError,
    isLoading,
  } = useQuery<CaseType, Error>({
    queryKey: ["caseDetails"],
    queryFn: () => getData("case/details", { caseId: caseId.toString() }),
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const addCartItemMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("cart/add", params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao adicionar o item ao carrinho. Tenta mais tarde!",
      ]);
    },
  });
  const removeCartItemMutation = useMutation({
    mutationFn: (params: Record<string, string>) =>
      deleteData("cart/remove", params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao remover o item do carrinho. Tenta mais tarde!",
      ]);
    },
  });

  useEffect(() => {
    if (caseDetailsError) {
      setWarningMessage([
        "error",
        "Houve um erro ao obter detalhes sobre o caso. Tenta mais tarde!",
      ]);
    }
  }, [caseDetailsError]);

  useEffect(() => {
    const groupedCartItem = groupedCart?.find(
      (groupedCartItem) => caseId === groupedCartItem.caseId
    );
    if (groupedCartItem) {
      setNumberCartItems(groupedCartItem.quantity);
    } else {
      setNumberCartItems(0);
    }
  }, [groupedCart]);

  useEffect(() => {
    if (caseDetails && isProduction) {
      ReactGA.send({
        hitType: "pageview",
        page: `/cases/${caseDetails?.path}`,
        title: `Case to buy - ${caseDetails?.name}`,
      });
    }
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", `Case to buy - ${caseDetails?.name}`);
    }
  }, [caseDetails]);

  const handleAddItemToCart = async () => {
    if (caseDetails) {
      addItemToCart({
        user: user,
        mutation: addCartItemMutation,
        setCart: setCart,
        item: caseDetails,
      });
    }
  };

  const handleRemoveItemToCart = async () => {
    const groupedCartItem = groupedCart?.find(
      (groupedCartItem) => caseId === groupedCartItem.caseId
    );
    if (groupedCartItem) {
      removeItemFromCart({
        user: user,
        mutation: removeCartItemMutation,
        setCart: setCart,
        groupedItem: groupedCartItem,
      });
    }
  };

  const handleBuyNow = async () => {
    handleAddItemToCart();
    navigate("/shopping-cart");
  };

  const handleScrollToReviews = () => {
    reviewsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const cartLoading =
    addCartItemMutation.isPending || removeCartItemMutation.isPending;

  return isLoading ? (
    <BigLoader />
  ) : (
    <CaseToBuyWrapper>
      <TopRightContent>
        <ProductImage
          src={`../../images/cases/${caseDetails?.path}/main_vertical.png`}
          alt="Product"
        />
        <Box>
          <ProductTitleBox>
            <Box>
              <BoldLargeText>{caseDetails?.name}</BoldLargeText>
            </Box>
            {/* <ShareButtonBox>
              <CustomBgButton textColor={COLORS.black} bgColor={COLORS.grayOne}>
                <ShareRoundedIcon style={{ width: "20px", height: "20px" }} />
              </CustomBgButton>
            </ShareButtonBox> */}
          </ProductTitleBox>
          {caseDetails?.averageClassification && (
            <ClassificationWrapper mt={2}>
              <Rating
                name="half-rating-read"
                defaultValue={caseDetails?.averageClassification}
                precision={0.05}
                readOnly
              />
              <CustomBgButton
                startIcon={
                  <StarRoundedIcon style={{ width: "20px", height: "20px" }} />
                }
                textColor={COLORS.redThree}
                bgColor={COLORS.redOne}
                onClick={handleScrollToReviews}
              >
                <SmallText color={`${COLORS.redThree} !important`}>
                  {caseDetails?.averageClassification}
                </SmallText>
              </CustomBgButton>
              <CustomBgButton
                textColor={COLORS.black}
                bgColor={COLORS.grayOne}
                onClick={handleScrollToReviews}
                startIcon={
                  <ReviewsRoundedIcon
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              >
                <SmallText>
                  {caseDetails?.reviews?.length} classificações
                </SmallText>
              </CustomBgButton>
            </ClassificationWrapper>
          )}
          <DividerSection />
          <DescriptionRules mt={2}>
            {caseDetails?.averageDuration && (
              <DescriptionRulesItem>
                <AccessTimeFilledRoundedIcon
                  style={{
                    width: "22px",
                    height: "22px",
                    color: PALETTE.secondaryFour,
                  }}
                />
                <MediumText>
                  {caseDetails?.averageDuration} de duração
                </MediumText>
              </DescriptionRulesItem>
            )}
            {caseDetails?.suggestedTeamSize && (
              <DescriptionRulesItem>
                <GroupsRoundedIcon
                  style={{
                    width: "22px",
                    height: "22px",
                    color: PALETTE.secondaryFour,
                  }}
                />
                <MediumText>
                  {caseDetails?.suggestedTeamSize} detetives
                </MediumText>
              </DescriptionRulesItem>
            )}
            {caseDetails?.minimumAge && (
              <DescriptionRulesItem>
                <PersonAddAltRoundedIcon
                  style={{
                    width: "28px",
                    height: "28px",
                    color: PALETTE.secondaryFour,
                  }}
                />
                <MediumText>
                  Não há idade mínima. Sugerido para +{caseDetails?.minimumAge}{" "}
                  anos
                </MediumText>
              </DescriptionRulesItem>
            )}
          </DescriptionRules>
          {(caseDetails?.averageDuration ||
            caseDetails?.minimumAge ||
            caseDetails?.suggestedTeamSize) && <DividerSection />}
          <Box mt={4}>
            <BoldMediumText my={2}>Descrição</BoldMediumText>
            <MediumText>{parse(caseDetails?.description || "")}</MediumText>
          </Box>
          <DividerSection />
          <PriceButtonBox mb={2} width="100% !important">
            <PriceBox>
              {caseDetails && caseDetails?.discount > 0 && (
                <MediumText
                  color={`${COLORS.redTwo} !important`}
                  sx={{ textDecorationLine: "line-through" }}
                >
                  {caseDetails?.price}
                </MediumText>
              )}
              {caseDetails?.price && caseDetails?.discount && (
                <BoldLargeText color={`${COLORS.greenOne} !important`}>
                  €
                  {Math.round(
                    caseDetails.price * (1 - caseDetails.discount) * 100
                  ) / 100}
                </BoldLargeText>
              )}
              {caseDetails && caseDetails?.discount > 0 && (
                <DiscountBox>
                  <MediumText>-{caseDetails?.discount * 100}%</MediumText>
                </DiscountBox>
              )}
            </PriceBox>
            {cartLoading ? (
              <LocalLoader height="40" />
            ) : (
              <CartBox>
                {numberCartItems > 0 ? (
                  <AddToCartMinusPlus
                    caseCartToBuy={false}
                    quantity={numberCartItems}
                    handleRemoveItemToCart={handleRemoveItemToCart}
                    handleAddItemToCart={handleAddItemToCart}
                  />
                ) : (
                  <MainButton
                    sx={{ width: { xs: "100%", sm: "300px" } }}
                    onClick={handleAddItemToCart}
                  >
                    <BoldMediumText color={`${COLORS.black} !important`}>
                      Adicionar ao carrinho
                    </BoldMediumText>
                  </MainButton>
                )}
                <SecondaryButton
                  sx={{ width: { xs: "100%", sm: "300px" } }}
                  onClick={() => {
                    handleBuyNow();
                  }}
                >
                  <BoldMediumText color={`${COLORS.white} !important`}>
                    Comprar agora
                  </BoldMediumText>
                </SecondaryButton>
                <NoBackgroundButton
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "300px",
                      lg: "620px",
                    },
                    border: "1px solid gray",
                  }}
                >
                  <RedeemIcon />
                  <BoldMediumText color={`${COLORS.black} !important`}>
                    Oferecer como prenda
                  </BoldMediumText>
                </NoBackgroundButton>
              </CartBox>
            )}
          </PriceButtonBox>
        </Box>
      </TopRightContent>
      <DividerSection />
      <Box mt={5}>
        <BoldMediumText mb={2}>Detalhes sobre o caso</BoldMediumText>
        <MediumText>{parse(caseDetails?.caseDetails || "")}</MediumText>
      </Box>
      <DividerSection />
      <BoldMediumText mb={3}>Conteúdos</BoldMediumText>
      <ContentWrapper mb={5}>
        <ContentImageBox>
          <ContentImage
            src={require("../../../assets/cases-content/teaser_content.png")}
            alt="agency logo"
          />
          <QuestionAnswerRoundedIcon
            style={{
              width: "40px",
              color: PALETTE.secondaryTwo,
              height: "40px",
              position: "absolute",
              top: "35%",
              left: "16%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <TravelExploreRoundedIcon
            style={{
              width: "40px",
              color: PALETTE.secondaryTwo,
              height: "40px",
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <ThumbUpRoundedIcon
            style={{
              width: "40px",
              color: PALETTE.secondaryTwo,
              height: "40px",
              position: "absolute",
              top: "20%",
              left: "67%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <NewspaperRoundedIcon
            style={{
              width: "40px",
              color: PALETTE.secondaryTwo,
              height: "40px",
              position: "absolute",
              top: "83%",
              left: "23%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <ReceiptRoundedIcon
            style={{
              width: "40px",
              color: PALETTE.secondaryTwo,
              height: "40px",
              position: "absolute",
              top: "70%",
              left: "83%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </ContentImageBox>
        <ContentTextBox>
          <MediumText>
            Para resolver o caso vais ter acesso à plataforma da Polícia de
            Portugal, onde vais encontrar diversos tipos de provas que deverás
            analisar e descodificar para chegar ao culpado.
          </MediumText>
          <MediumText mt={2}>Encontrarás:</MediumText>
          <BoldMediumText>‣ Mensagens entre os suspeitos;</BoldMediumText>
          <BoldMediumText>
            ‣ Cortes de redes sociais da vítima ou dos suspeitos;
          </BoldMediumText>
          <BoldMediumText>‣ Capas de jornal;</BoldMediumText>
          <BoldMediumText>
            ‣ Análises de laboratório do local do crime;
          </BoldMediumText>
          <BoldMediumText>‣ Recibos de pagamentos;</BoldMediumText>
          <BoldMediumText>‣ Áudios (entrevistas, escutas...);</BoldMediumText>
          <BoldMediumText>‣ Outros...</BoldMediumText>
        </ContentTextBox>
      </ContentWrapper>
      <DividerSection />
      <Reviews reviews={caseDetails?.reviews} reviewsRef={reviewsRef} />
    </CaseToBuyWrapper>
  );
}
