import { BoldSmallText, MediumText, SmallText } from "../../utils/GlobalStyles";
import { GroupedCartItemType } from "../../utils/Interfaces/Cart";
import {
  CheckoutOfferCartItemBox,
  CheckoutOfferCartItemImage,
  LeftColumnBox,
  SubCostBox,
  TextInfoBox,
} from "./styles";
import { COLORS } from "../../assets/theme";
import { CaseType } from "../../utils/Interfaces/Case";

export default function CheckoutOfferCartItem({
  caseItem,
}: {
  caseItem: CaseType;
}) {
  return (
    <CheckoutOfferCartItemBox>
      <CheckoutOfferCartItemImage
        src={`../../images/cases/${caseItem.path}/main_horizontal.png`}
        alt="cart-item"
      />
      <TextInfoBox>
        <LeftColumnBox>
          <MediumText>{caseItem.name}</MediumText>
          <SubCostBox>
            <SmallText>{caseItem.teaserSummary}</SmallText>
          </SubCostBox>
        </LeftColumnBox>
        <MediumText color={`${COLORS.black} !important`}>
          €{caseItem.price}
        </MediumText>
      </TextInfoBox>
    </CheckoutOfferCartItemBox>
  );
}
