import { createContext } from "react";
import {
  AuthenticationContextType,
  GeneralAppDataContextType,
} from "../Interfaces/Global";
import {
  CheckoutContextType,
  CheckoutOfferContextType,
} from "../Interfaces/Payment";

export const GeneralAppDataContext =
  createContext<GeneralAppDataContextType | null>(null);

export const AuthenticationContext =
  createContext<AuthenticationContextType | null>(null);

export const CheckoutContext = createContext<CheckoutContextType>({
  clientSecret: null,
  setClientSecret: () => {},
  step: "",
  setStep: () => {},
});

export const CheckoutOfferContext = createContext<CheckoutOfferContextType>({
  senderName: "",
  setSenderName: () => {},
  senderEmail: "",
  setSenderEmail: () => {},
  receiverName: "",
  setReceiverName: () => {},
  receiverEmail: "",
  setReceiverEmail: () => {},
  message: "",
  setMessage: () => {},
});
