import {
  FileData,
  GameMainContentProps,
} from "../../../../utils/Interfaces/Game";
import FileItem from "./FileItem";
import { GameMainContentWrapper } from "./styles";

function GameMainContent({
  bucketUrl,
  files,
  activeFolder,
}: GameMainContentProps) {
  return (
    <GameMainContentWrapper>
      {files[activeFolder]?.map((file: FileData) => <FileItem file={file} />)}
    </GameMainContentWrapper>
  );
}

export default GameMainContent;
