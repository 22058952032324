import { PDFDocument, rgb } from "pdf-lib";
import { downloadBlob } from "./DownloadFileFromBucket";
import fontkit from "@pdf-lib/fontkit";
const bahnschriftFont = require("../../assets/fonts/BAHNSCHRIFT.TTF");

export const addTextToPdf = async (folderName: string, name: string, caseName: string) => {
  const certificatePdf = require(
    `../../assets/cases-content/${folderName}/certificate.pdf`,
  );
  const existingPdfBytes = await fetch(certificatePdf).then((res) =>
    res.arrayBuffer(),
  );
  const fontBytes = await fetch(bahnschriftFont).then((res) =>
    res.arrayBuffer(),
  );

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  pdfDoc.registerFontkit(fontkit);
  const font = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const { height } = firstPage.getSize();

  firstPage.drawText(`Obrigado, Detetive ${name}!`, {
    x: 93,
    y: height / 2 + 140,
    size: 20,
    font: font,
    color: rgb(0.9647058823529412, 0.788235294117647, 0),
  });

  const pdfBytes = await pdfDoc.save();

  downloadBlob(
    new Blob([pdfBytes], { type: "application/pdf" }),
    `DESARQUIVADOS - ${caseName} (certificado).pdf`,
  );
};
