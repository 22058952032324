import { Box, styled } from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { COLORS } from "../../../../../assets/theme";

interface FileWrapperProps {
  isPdforImage: boolean;
}

export const FileWrapper = styled(Box)<FileWrapperProps>`
  width: 275px;
  position: relative;
  height: 350px;
  border-radius: 4px;
  outline: 2px solid ${COLORS.black};
  overflow: hidden;
  background-color: ${COLORS.grayOne};
  cursor: pointer;

  &:hover {
    background-color: ${({ isPdforImage }) =>
      isPdforImage && "rgba(0, 0, 0, 0.3)"};
    .child-box {
      display: ${({ isPdforImage }) => isPdforImage && "flex"};
    }
  }
`;

export const FileBox = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const FileIconBox = styled(Box)`
  width: 20px;
  height: 20px;
  color: ${COLORS.redThree};
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;

export const AudioPlayerBox = styled(Box)`
  height: 305px;
  background-color: ${COLORS.white};
  display: flex;
  align-items: center;
`;

export const FileNameBox = styled(Box)`
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding: 5px 10px;
  z-index: 1;
  background-color: ${COLORS.grayOne};
  position: absolute;
  bottom: 0;
`;

export const AspectRatioIconWrapper = styled(AspectRatioIcon)`
  height: 30px;
  width: 30px;
  color: ${COLORS.white};
  position: absolute;
  top: 135px;
  left: 120px;
  z-index: 1;
`;
