import { useContext, useEffect, useRef, useState } from "react";
import {
  CheckoutContextType,
  CheckoutOfferContextType,
  CheckoutSessionType,
} from "../../../utils/Interfaces/Payment";
import {
  CheckoutContext,
  CheckoutOfferContext,
  GeneralAppDataContext,
} from "../../../utils/GeneralContext";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import { getData, postData } from "../../../utils/endpoints/api";
import {
  BoldMediumText,
  DividerSection,
  MainButton,
  NoBackgroundButton,
  SmallText,
  TitleMediumText,
} from "../../../utils/GlobalStyles";
import { CardWrapper, CheckoutStepsWrapper } from "../styles";
import {
  CheckoutBoxLowerSection,
  CheckoutBoxUpperSection,
  CheckoutDetailsBox,
  SubDividerSection,
} from "../BillingInformation/styles";
import { Box, Link, TextField } from "@mui/material";
import { CaseType } from "../../../utils/Interfaces/Case";
import { useParams } from "react-router-dom";
import CheckoutOfferCartItem from "../../CheckoutOfferCartItem";
import { COLORS } from "../../../assets/theme";
import { generateCheckoutSessionItems } from "../../../utils/HelpFunctions";
import {
  CartItemType,
  CartType,
  GroupedCartItemType,
} from "../../../utils/Interfaces/Cart";
import { groupCartByItem } from "../../CartItem/helpers";
import { v4 as uuidv4 } from "uuid";
import LocalLoader from "../../loaders/LocalLoader";

const OfferCaseResume = () => {
  const { caseId } = useParams();
  const { setWarningMessage } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const { clientSecret, setClientSecret, setStep } = useContext(
    CheckoutContext
  ) as CheckoutContextType;
  const { senderEmail, senderName, receiverEmail, receiverName, message } =
    useContext(CheckoutOfferContext) as CheckoutOfferContextType;
  const [tempClientSecret, setTempClientSecret] =
    useState<CheckoutSessionType | null>(null);

  const {
    data: caseDetails,
    error: caseDetailsError,
    isLoading,
  } = useQuery<CaseType, Error>({
    queryKey: ["caseDetails"],
    queryFn: () => getData("case/details", { caseId: caseId!!.toString() }),
    enabled: !!caseId,
  });
  const checkoutSessionCreateMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("checkout/create-offer", params, body, true, false),
    onSuccess: (data) => {
      setTempClientSecret(data);
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao criar o checkout. Tenta mais tarde!",
      ]);
    },
  });

  useEffect(() => {
    createCheckoutSession();
  }, [caseDetails]);

  async function createCheckoutSession() {
    if (caseDetails) {
      try {
        const cartItem: CartItemType = {
          id: uuidv4(),
          caseId: caseDetails.caseId,
          name: caseDetails.name,
          imageUrl: caseDetails.imageUrl,
          priceId: caseDetails.priceId,
          price: caseDetails.price,
          discount: caseDetails.discount,
          difficulty: caseDetails.difficulty,
          teaserSummary: caseDetails.teaserSummary,
          path: caseDetails.path,
        };
        const cartItems: CartType = { totalAmount: 0, items: [] };
        cartItems.items?.push(cartItem);
        const groupedItems: GroupedCartItemType[] = groupCartByItem(
          cartItems.items
        );
        const checkoutItems = generateCheckoutSessionItems(
          window.location.origin,
          senderEmail,
          senderEmail,
          groupedItems,
          cartItems
        );
        checkoutItems["formData"] = {
          senderName: senderName,
          senderEmail: senderEmail,
          receiverName: receiverName,
          receiverEmail: receiverEmail,
          message: message,
        };
        await checkoutSessionCreateMutation.mutateAsync({
          params: {},
          body: checkoutItems,
        });
      } catch (error) {
        console.error("Error creating checkout session:", error);
      }
    }
  }

  const handleGoToPayment = () => {
    if (tempClientSecret) {
      setClientSecret(tempClientSecret);
    }
  };

  const handleGoBack = () => {
    setStep("VOUCHER_FORM");
  };

  if (!caseDetails) {
    return null;
  }

  return (
    <CheckoutStepsWrapper>
      <TitleMediumText>Checkout para oferta</TitleMediumText>
      <CheckoutDetailsBox>
        <CardWrapper>
          <CheckoutBoxUpperSection>
            <BoldMediumText sx={{ whiteSpace: "wrap" }}>
              Resumo dos dados para enviar a prenda
            </BoldMediumText>
            <NoBackgroundButton onClick={handleGoBack} sx={{ padding: 0 }}>
              <ModeEditIcon style={{ width: "16px", height: "16px" }} />
              Editar
            </NoBackgroundButton>
          </CheckoutBoxUpperSection>
          <DividerSection sx={{ margin: "0" }} />
          <CheckoutBoxLowerSection>
            <CheckoutOfferCartItem caseItem={caseDetails!!} />
          </CheckoutBoxLowerSection>
          <SubDividerSection sx={{ margin: "0" }} />
          <Box display="flex" gap={1}>
            <TextField
              sx={{ width: "100%", backgroundColor: "white" }}
              size="small"
              autoComplete="name"
              label="O teu nome"
              variant="outlined"
              value={senderName}
              disabled
            />
            <TextField
              sx={{ width: "100%", backgroundColor: "white" }}
              size="small"
              autoComplete="email"
              label="O teu email"
              variant="outlined"
              placeholder="name@mail.com"
              value={senderEmail}
              disabled
            />
          </Box>
          <Box display="flex" gap={1}>
            <TextField
              sx={{ width: "100%", backgroundColor: "white" }}
              size="small"
              label="O nome do destinatário"
              variant="outlined"
              value={receiverName}
              disabled
            />
            <TextField
              sx={{ width: "100%", backgroundColor: "white" }}
              size="small"
              label="O email do destinatário"
              variant="outlined"
              placeholder="name@mail.com"
              value={receiverEmail}
              disabled
            />
          </Box>
          <TextField
            label="Mensagem a enviar"
            name="message"
            sx={{ backgroundColor: COLORS.white }}
            variant="outlined"
            multiline
            rows={5}
            fullWidth
            placeholder="Escreve a tua mensagem para o destinatário..."
            value={message}
            disabled
          />
          <SubDividerSection sx={{ margin: "0" }} />
          <CheckoutBoxLowerSection>
            <MainButton
              sx={{ opacity: tempClientSecret ? "1" : "0.5" }}
              disabled={!tempClientSecret}
              size="medium"
              fullWidth
              onClick={handleGoToPayment}
            >
              {!tempClientSecret ? (
                <LocalLoader colorOverride={COLORS.redTwo} height="40" />
              ) : (
                <BoldMediumText>Ir para pagamento</BoldMediumText>
              )}
            </MainButton>
            <SmallText>
              Os códigos promocionais serão aplicados no próximo passo
            </SmallText>
          </CheckoutBoxLowerSection>
        </CardWrapper>
        <SmallText width={"100%"}>
          *se pretendes fatura com número de contribuinte, envia um email para{" "}
          <Link href="mailto: info@desarquivados.com">
            info@desarquivados.com
          </Link>{" "}
          com o nome, NIF e morada que pretendes na fatura.
        </SmallText>
      </CheckoutDetailsBox>
    </CheckoutStepsWrapper>
  );
};

export default OfferCaseResume;
